import axios from 'axios'
import store from '../store'

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    const { user } = store.state

    if (user) {
      config.headers.Authorization = `${user.data.token_type} ${user.data.access_token}`
    }
    return config
  },
  (error) => Promise.reject(error)
)

const Request = {
  postRequest(endpoint, data) {
    return axios.post(`${process.env.VUE_APP_BASE_URL}/${endpoint}`, data)
  },

  getRequest(endpoint) {
    return axios.get(`${process.env.VUE_APP_BASE_URL}/${endpoint}`)
  },
  patchRequest(endpoint, data) {
    return axios.patch(`${process.env.VUE_APP_BASE_URL}/${endpoint}`, data)
  },
  deleteRequest(endpoint) {
    return axios.delete(`${process.env.VUE_APP_BASE_URL}/${endpoint}`)
  },
}

export default Request
