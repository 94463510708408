<template>
  <LoadingSpinner v-if="loading" />
  <main class="flex justify-between mx-32 my-16">
        <dashboard-card class="bg-cardBackgroundColor-pink">
            <template v-slot:card-heading>
                <div class="py-5 px-7 tracking-widest uppercase">Operating Expenditure</div>
            </template>
            <template v-slot:expenditure-amount>
                <div>
                    <div class="pb-2 tracking-widest uppercase text-sm">AMOUNT</div>
                    <div class="text-3xl">N{{ $filters.currencyUSD(totalOperatingExpenditure) }}</div>
                </div>
            </template>
            <template v-slot:expenditure-balance>
                <div>
                    <div class="pb-2 tracking-widest uppercase text-sm">Balance</div>
                    <div class="text-3xl">N{{ $filters.currencyUSD(operatingExpensesBalance) }}</div>
                </div>
            </template>
        </dashboard-card>
        <dashboard-card class="bg-cardBackgroundColor-purple">
            <template v-slot:card-heading>
                <div class="py-5 px-7 tracking-widest uppercase">Capital Expenditure</div>
            </template>
            <template v-slot:expenditure-amount>
                <div>
                    <div class="pb-2 tracking-widest uppercase text-xs">AMOUNT</div>
                    <div class="text-3xl">N{{ $filters.currencyUSD(totalCapitalExpenditure) }}</div>
                </div>
            </template>
            <template v-slot:expenditure-balance>
                <div>
                    <div class="pb-2 tracking-widest uppercase text-xs">Balance</div>
                    <div class="text-3xl">N{{ $filters.currencyUSD(capitalExpensesBalance) }}</div>
                </div>
            </template>
        </dashboard-card>
        <div class="w-1/3 bg-cardBackgroundColor-green text-white rounded-md">
            <div class="py-5 px-7 tracking-widest uppercase">Expenditure request</div>
            <hr>
            <div class="flex pt-5 px-7">
                <div class="w-1/2">
                    <div class="pb-2 tracking-widest uppercase text-xs">pending</div>
                    <div class="text-3xl">{{ pendingExpenditureRequest }}</div>
                </div>
                <div class="w-1/2">
                    <div class="pb-2 tracking-widest uppercase text-xs">approved</div>
                    <div class="text-3xl">{{ approvedExpenditureRequest }}</div>
                </div>
            </div>
            <div class="flex py-5 px-7">
                <div class="w-1/2">
                    <div class="pb-2 tracking-widest uppercase text-xs">this week</div>
                    <div class="text-3xl">{{ expenditureRequestForTheWeek }}</div>
                </div>
                <div class="w-1/2">
                    <div class="pb-2 tracking-widest uppercase text-xs">this month</div>
                    <div class="text-3xl">{{ expenditureRequestForTheMonth }}</div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import DashboardCard from '@/components/ui/DashboardCard.vue'
import Request from '@/services/requestHelper'

export default {
  components: { DashboardCard },
  data() {
    return {
      totalOperatingExpenditure: 0,
      totalCapitalExpenditure: 0,
      pendingExpenditureRequest: 0,
      approvedExpenditureRequest: 0,
      expenditureRequestForTheWeek: 0,
      expenditureRequestForTheMonth: 0,
      operatingExpensesBalance: 0,
      capitalExpensesBalance: 0,
      errorMessage: null,
      loading: true
    }
  },
  async mounted() {

    try {
      const { status, data } = await Request.getRequest('budget/total')

      if (status === 200) {
        this.totalOperatingExpenditure = data.data.operating_expenses
        this.totalCapitalExpenditure = data.data.capital_expenses
        this.pendingExpenditureRequest = data.data.pending_expenditure_request
        this.approvedExpenditureRequest = data.data.approved_expenditure_request
        this.expenditureRequestForTheWeek = data.data.expenditure_request_for_the_week
        this.expenditureRequestForTheMonth = data.data.expenditure_request_for_the_month
        this.operatingExpensesBalance = data.data.operating_expenses_balance
        this.capitalExpensesBalance = data.data.capital_expenses_balance
        this.loading = false

      }
    } catch (error) {
      this.errorMessage = error.response.data.message
    }
  }
}
</script>
