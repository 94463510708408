<template>
  <error-toast :errorMessage="errorMessage" @resetErroMessage="errorMessage = null">
    <template v-slot:error-message>
      <span class="pl-2 text-md">{{ errorMessage }}</span>
    </template>
  </error-toast>
  <main class="sticky top-0 z-40" :class="{'hidden': $route.name === 'Login'}">
    <nav class="flex items-center bg-primaryColor py-3 px-32 text-white">
      <img src="@/assets/images/logo.svg" alt="logo">
      <span class="border borderColor h-9 mx-4"></span>
      <div>
        <div>Finance</div>
        <div>Management System</div>
      </div>
    </nav>
    <div class="px-32 text-md text-white bg-navBgColor flex justify-between">
      <div class="flex items-center capitalize">
          <router-link :to="{ name: 'Dashboard' }" class="px-10 py-4">dashboard</router-link>
          <router-link :to="{ name: 'Budget' }" class="px-10 py-4" :class="{ 'router-link-exact-active': routeIsBudget }">budget</router-link>
          <router-link :to="{ name: 'Expenditure' }" class="px-10 py-4" :class="{ 'router-link-exact-active': routeIsExpenditure }">expenditure</router-link>
      </div>
      <div v-if="user" class="flex items-center relative">
          <div class="flex items-center justify-center w-10 h-10 rounded-full bg-white text-primaryColor font-semibold text-xl">{{ user.data.name.charAt(0).toUpperCase() }}</div>
          <div class="capitalize ml-4">
              <div>{{ user.data.name }}</div>
              <div class="text-xs italic text-right font-thin">{{ user.data.position }}</div>
          </div>
          <img @click="loggingOut = !loggingOut" src="@/assets/images/chevron-down-icon.svg" class="-mt-3 cursor-pointer px-3 py-4" alt="arrow down icon">
          <div @click="logOutUser" v-if="loggingOut" class="absolute top-full -right-6 w-full bg-interactionBg text-left text-fontColor text-lg border border-borderColor rounded-md py-2 px-3 cursor-pointer capitalize hover:bg-gray-200">
            logout
          </div>
      </div>
    </div>
  </main>
  <router-view/>
</template>

<script>
import Request from '@/services/requestHelper'
import { mapState } from 'vuex'

export default {
  mounted() {
    const auth = JSON.parse(localStorage.getItem('lcl_auth_user'))

    if (!auth) {
      this.$router.push({ name: 'Login' })
    } else {
      this.$store.dispatch('updateUserState', auth)
    }
  },
  data() {
    return {
      loggedInUser: null,
      loggingOut: false,
      errorMessage: null
    }
  },
  computed: {
    ...mapState(['user']),
    routeIsBudget() {
      return this.searchString('budget')
    },
    routeIsExpenditure() {
      return this.searchString('expenditure')
    }
  },
  methods: {
    searchString(string) {
      return this.$route.path.includes(string)
    },
    async logOutUser() {
      try {
        const { data } = await Request.postRequest('logout', { email: this.user.data.email })

        if (data.message.length) {
          this.loggingOut = false
          localStorage.removeItem('lcl_auth_user')
          this.$store.dispatch('logOutUser')
          this.$router.push({ name: 'Login' })
        }
      } catch (error) {
        this.errorMessage = error.response.data.message
      }
    }
  }
}
</script>

<style>
  :root {
    --primary-color: #1269FA;
    --font-color: #42505C;
  }

  #app {
    font-family: "sf-pro-regular";
  }

  .router-link-exact-active {
    border-bottom: 2px solid #fff;
  }

  .v3dp__datepicker {
    width: 50%
  }

  .v3dp__datepicker input {
    width: 96%;
    height: 37px;
    padding: 0 8px;
  }

  .v3dp__datepicker input:focus {
    outline: none;
  }
</style>
